import React, { useMemo } from 'react';
import LoadingIndicator from '../../../../components/global/LoadingIndicator/LoadingIndicator';
import { Alert } from 'antd';
import CalculationStatistics from './CalculationStatistics';
import { Column, computeRemSize, Row } from '@ydistri/ds';
import { styled } from 'styled-components';
import { useApplicationData } from '../../../../hooks/useApplicationData';
import { useGetStatisticsQuery } from '../../../CalculationDetail/Statistics/apiCalculationStatistics';
import { CalculationStatisticClusterType } from '@ydistri/api-sdk';
import CalculationsTable, { CalculationDataType } from '../table/CalculationsTable';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../../store';

const CalculationStatisticsWrapper = styled.div`
  margin-left: 7.5rem;
`;

interface CalculationExpandControllerProps {
  calculationsTableId: string;
  calculationId: number;
  subordinateCalculations?: CalculationDataType[] | null;
  displaySkuTypeValues?: boolean;
}

/**
 * Handles fetching and displaying calculation statistics or error messages
 * @param calculationsTableId
 * @param calculationId
 * @param subordinateCalculations
 * @param displaySkuTypeValues
 * @constructor
 */
const CalculationExpandController: React.FC<CalculationExpandControllerProps> = ({
  calculationsTableId,
  calculationId,
  subordinateCalculations,
  displaySkuTypeValues = true,
}) => {
  const { data, isFetching, isError } = useGetStatisticsQuery({
    calculationId,
    clusterType: CalculationStatisticClusterType.StoreDepartment,
  });
  const appData = useApplicationData();

  const expandedRowKeys = useSelector(
    (state: ReduxState) => state.calculations.expandedRowKeys[calculationsTableId],
  );
  const expandedSubmergedCalculations = useSelector(
    (state: ReduxState) => state.calculations.expandedSubmergedCalculations[calculationsTableId],
  );

  const hasExpandedStatistics = (expandedRowKeys ?? []).includes(calculationId);
  const hasExpandedSubmergedCalculations = (expandedSubmergedCalculations ?? []).includes(
    calculationId,
  );

  const statisticsComponent = useMemo(() => {
    if (isFetching) {
      return (
        <Row>
          <CalculationStatisticsWrapper>
            <LoadingIndicator />
          </CalculationStatisticsWrapper>
        </Row>
      );
    } else if (isError) {
      return <Alert message="Failed to load statistics" type="error" />;
    } else {
      if (data) {
        return (
          <CalculationStatisticsWrapper>
            <CalculationStatistics
              statistics={data}
              showForcedProductsStats={appData.forceQuantities}
              showMinimalLayerStats={appData.minimalLayers}
            />
          </CalculationStatisticsWrapper>
        );
      } else {
        return <Alert message="No statistics loaded" type="warning" />;
      }
    }
  }, [appData.forceQuantities, appData.minimalLayers, data, isError, isFetching]);

  return (
    <Column $gap="1rem" $padding={4}>
      {hasExpandedSubmergedCalculations && (
        <Row $minWidth={computeRemSize(700)} $width="70%">
          {subordinateCalculations ? (
            <CalculationsTable
              calculationsTableId={`overlapping-calculations-${calculationId}`}
              predefinedCalculations={subordinateCalculations}
              variant="compact"
              selectable={false}
              autoHeight={true}
              displayCreationTime={false}
              displaySkuTypeValues={displaySkuTypeValues}
            />
          ) : (
            'No submerged calculations present.'
          )}
        </Row>
      )}
      {hasExpandedStatistics && <Row>{statisticsComponent}</Row>}
    </Column>
  );
};

export default CalculationExpandController;
