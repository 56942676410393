import React, { useCallback } from 'react';
import { MdOutlineImportExport } from 'react-icons/md';
import { Button, IconButton_IconSizes } from '@ydistri/ds';
import { useRunManualCalculationMutation } from './apiCalculations';
import { useTemplateOrCalculation } from '../../hooks/useTemplateOrCalculation';
import { CalculationStatus } from '@ydistri/api-sdk';
import { useGetCalculationQuery } from '../CalculationDetail/apiCalculationDetail';
import { useNavigate, useParams } from 'react-router-dom';
import { RoutingPage } from '../../routes/routerSlice';
import { addToast } from '@ydistri/utils';
import { useDispatch } from 'react-redux';

interface RecomputeStatisticsButtonProps {
  calculationStatus?: CalculationStatus;
  disabled?: boolean;
}

const importIcon = <MdOutlineImportExport size={IconButton_IconSizes.small} />;

const RecomputeStatisticsButton: React.FC<RecomputeStatisticsButtonProps> = ({
  calculationStatus,
  disabled = false,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { projectShortName } = useParams();

  const templateOrCalculation = useTemplateOrCalculation();
  const { data: calculationData } = useGetCalculationQuery(templateOrCalculation.id);
  const [runManualCalculation, { isLoading: sendingRunCommand }] =
    useRunManualCalculationMutation();

  const onClickHandler = useCallback(() => {
    if (templateOrCalculation.type === 'Calculation') {
      const createErrorToast = () =>
        dispatch(
          addToast({
            message: `There was an error when trying to recompute statistics`,
            isError: true,
          }),
        );

      runManualCalculation(templateOrCalculation.id)
        .unwrap()
        .then(() => {
          dispatch(addToast({ message: 'Statistics recomputation will start shortly' }));
          navigate(`/${projectShortName}/${RoutingPage.CALCULATIONS}`);
        })
        .catch(() => {
          createErrorToast();
        });
    }
  }, [
    dispatch,
    navigate,
    projectShortName,
    runManualCalculation,
    templateOrCalculation.id,
    templateOrCalculation.type,
  ]);

  if (calculationStatus === CalculationStatus.Running) {
    disabled = true;
  }

  const loading = sendingRunCommand || calculationData?.status === CalculationStatus.Running;

  return (
    <Button
      $colorVariant={disabled ? 'ghost' : 'primary'}
      $textVariant="semibold"
      icon={importIcon}
      type="primary"
      onClick={onClickHandler}
      loading={loading}
      disabled={disabled || loading}
    >
      Recompute statistics
    </Button>
  );
};

export default RecomputeStatisticsButton;
