import { ButtonProps, Modal } from 'antd';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Text, Column, Row, ReadOnlyItem } from '@ydistri/ds';
import { TemplateSelect } from '../../Configuration/TemplateSelect';
import { useLoadCalculationToTemplateMutation } from '../apiCalculations';
import { addToast } from '../../../../../../../libs/utils/src/lib/toastSlice';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { RoutingPage } from '../../../routes/routerSlice';
import { ConfigurationSubpage } from '../../Configuration/Configuration';
import { useDispatch } from 'react-redux';
import { useGetTemplatesQuery } from '../../../apis/apiApplication';
import { getApiHeader } from '../../../apis/getApiHeader';
import { API } from '../../../swagger/collections';
import { skipToken } from '@reduxjs/toolkit/query';
import { useGetCalculationQuery } from '../../CalculationDetail/apiCalculationDetail';
import { styled } from 'styled-components';
import { Overlay } from '../../../components/global/Overlay';
import OverlayMessage, {
  OverlayMessageIcon,
} from '../../../components/global/ContentOverlay/OverlayComponents';
import { ErrorType } from '../../../apis/api';

const MODAL_LABEL_WIDTH = '10rem';
const TEMPLATE_SELECT_LABEL_STYLE = { marginTop: '0.5rem', display: 'inline-block' };

const loadingOverlayContent = <OverlayMessage title="Loading" icon={OverlayMessageIcon.SPINNER} />;

const SelectCalculationTemplateText = styled(Text)`
  cursor: pointer;
  margin-left: auto;
  display: inline-block;
  margin-right: 0.5rem;
  color: ${({ theme }) => theme.colors.primary};
`;

interface UseCalculationTemplateActionProps {
  templateId?: number;
  onClick: (templateId: number) => void;
}

/**
 * Action to select the template from the calculation.
 * @param template Source template of the calculation
 * @param onClick event handler called when user clicks the action
 * @constructor
 */
const SelectCalculationTemplateAction: React.FC<UseCalculationTemplateActionProps> = ({
  templateId,
  onClick,
}) => {
  const clickHandler = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      if (templateId) {
        onClick(templateId);
      }
    },
    [templateId, onClick],
  );

  if (templateId) {
    return (
      <SelectCalculationTemplateText onClick={clickHandler}>
        Use target template from calculation
      </SelectCalculationTemplateText>
    );
  }
};

interface LoadIntoTemplateModalProps {
  calculationId: number;
  calculationTitle: string;
  onConfirmed?: () => void;
  onCanceled: () => void;
}

const LoadIntoTemplateModal: React.FC<LoadIntoTemplateModalProps> = ({
  calculationId,
  calculationTitle,
  onConfirmed,
  onCanceled,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { projectShortName } = useParams();
  const [templateId, setTemplateId] = React.useState<number | undefined>();
  const [loadCalculationToTemplate, loadCalculationToTemplateStatus] =
    useLoadCalculationToTemplateMutation({
      fixedCacheKey: 'loading-to-template',
    });

  const { data: templates, isFetching: isTemplatesFetching } = useGetTemplatesQuery(
    getApiHeader(API, 'Project-Code') ?? skipToken,
  );
  const { data: calculation, isFetching: isCalculationFetching } =
    useGetCalculationQuery(calculationId);

  useEffect(() => {
    if (templates && templates.length === 1) {
      setTemplateId(templates[0].id);
    }
  }, [templates]);

  const cancelHandler = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      onCanceled();
    },
    [onCanceled],
  );

  const okHandler = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();

      if (templateId) {
        loadCalculationToTemplate({
          fromCalculationId: calculationId,
          toTemplateId: templateId,
        })
          .unwrap()
          .then(() => {
            if (onConfirmed) onConfirmed();
            dispatch(
              addToast({
                message: `Configuration from calculation #${calculationId} was loaded`,
              }),
            );

            if (projectShortName) {
              navigate(
                generatePath(
                  `/:projectShortName/${RoutingPage.CONFIGURATION}/:templateId/${ConfigurationSubpage.CATEGORIES}/1`,
                  { templateId: templateId.toString(), projectShortName },
                ),
              );
            }
          })
          .catch((error: ErrorType) => {
            dispatch(
              addToast({
                message: `Configuration from calculation #${calculationId} was not loaded: ${error.response.data.Messages.join(
                  ', ',
                )}`,
                isError: true,
              }),
            );
          });
      } else {
        onCanceled();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      templateId,
      onConfirmed,
      calculationId,
      loadCalculationToTemplate,
      dispatch,
      projectShortName,
      onCanceled,
    ],
  );

  //The modal is wrapped in a div to prevent the click event from bubbling up to the parent
  //and selecting the calculation below the modal
  const onWrapperClick = useCallback((event: React.MouseEvent) => event.stopPropagation(), []);

  const templateChanged = useCallback((newTemplateId: number) => setTemplateId(newTemplateId), []);

  const okButtonProps: ButtonProps = useMemo(() => {
    const isDisabled = !templateId; //if the template is not selected, the OK button is disabled

    return {
      disabled: isDisabled,
    };
  }, [templateId]);

  const onSelectCalculationTemplate = useCallback((templateId: number) => {
    setTemplateId(templateId);
  }, []);

  return (
    <div onClick={onWrapperClick}>
      <Modal
        data-type="modal"
        open={true}
        title="Load configurations"
        closable={false}
        onCancel={cancelHandler}
        onOk={okHandler}
        confirmLoading={loadCalculationToTemplateStatus.isLoading}
        okText="Load"
        okButtonProps={okButtonProps}
        maskClosable={false}
        width="60rem"
      >
        <Column>
          <Row>
            <Text>
              Load configurations from already computed calculation to template selected below.
            </Text>
          </Row>
          <Overlay
            active={isCalculationFetching || isTemplatesFetching}
            overlayContent={loadingOverlayContent}
          >
            <Column $padding={4} $gap="1rem">
              <ReadOnlyItem
                label="Source calculation"
                labelWidth={MODAL_LABEL_WIDTH}
                value={`#${calculationId}: ${calculationTitle}`}
              />
              <ReadOnlyItem
                label="Target template"
                labelWidth={MODAL_LABEL_WIDTH}
                alignItems="normal"
                labelStyle={TEMPLATE_SELECT_LABEL_STYLE}
              >
                <Column>
                  <TemplateSelect onChange={templateChanged} templateId={templateId} />
                  {
                    //the use source template action makes sense only when there is a source template and there are more than
                    // one template because if only one template is available then it is already selected
                  }
                  {calculation?.sourceTemplate && templates && templates.length > 1 && (
                    <SelectCalculationTemplateAction
                      templateId={calculation.sourceTemplate.id}
                      onClick={onSelectCalculationTemplate}
                    />
                  )}
                </Column>
              </ReadOnlyItem>
            </Column>
          </Overlay>
        </Column>
      </Modal>
    </div>
  );
};

export default LoadIntoTemplateModal;
