import { ReadOnlyItem, Row, Section, Text } from '@ydistri/ds';
import React, { useCallback, useMemo } from 'react';
import { BrandResponse, DisplayEntityIdType, ProductResponse } from '@ydistri/api-sdk';
import CalculationRedistributionSectionHeader from './CalculationRedistributionSectionHeader';
import { useGetCalculationSkusQuery } from '../../Calculations/apiCalculationsSkus';
import { extractCategoryTitles, trimArray } from './redistributionLib';
import { Divider, Popover, Space, Spin } from 'antd';
import { isSameId } from '../../../lib/utils/utils';
import { SYSTEM_ID_LABEL } from '../../../components/global/_constants';
import { useApplicationConfiguration } from '../../../hooks/useApplicationConfiguration';
import { generatePath, useParams } from 'react-router-dom';
import { ROUTES } from '../../../components/menu/menuLeftItemTemplate';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../store';

interface CategoryListProps {
  categories: string[];
  maxCategories?: number;
}

/**
 * Display a list of categories separated by a vertical divider. If the list is longer than the maxCategories prop, the
 * list will be trimmed and a tooltip will be displayed with the full list.
 * @param categories
 * @param maxCategories Display only given number of categories. Component will display the first and last maxCategories - 1
 * @constructor
 */
const CategoryList: React.FC<CategoryListProps> = ({ categories, maxCategories = 4 }) => {
  const trimCategories = categories.length > maxCategories;

  const body = useCallback((items: string[]) => {
    return (
      <Space split={splitter}>
        {items.map((categoryName, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Text key={`${categoryName}-${i}`}>{categoryName}</Text>
        ))}
      </Space>
    );
  }, []);

  if (trimCategories) {
    const tmpCategoryNames = trimArray(categories, maxCategories, '...');
    const tooltipContent = categories.join(' / ');
    return (
      <Popover content={tooltipContent} placement="bottomLeft" trigger="hover">
        {body(tmpCategoryNames)}
      </Popover>
    );
  } else {
    return body(categories);
  }
};

interface CalculationRedistributionProductSummaryProps {
  product: ProductResponse;
  skuId: number;
  calculationId: number;
  brand?: BrandResponse;
}

const splitter = <Divider type="vertical" />;

const CalculationRedistributionProductSummary: React.FC<
  CalculationRedistributionProductSummaryProps
> = ({ product, skuId, calculationId, brand }) => {
  const { displayProductId } = useApplicationConfiguration();
  const { projectShortName } = useParams();
  const selectedCategorySlug = useSelector(
    (state: ReduxState) => state.categoryTreeReducer.currentlySelectedCategorySlug,
  );

  const { data: skuConfiguration, isFetching } = useGetCalculationSkusQuery({
    skuId: skuId,
    calculationId: calculationId,
  });
  const categoryNames = useMemo(() => {
    if (skuConfiguration) {
      return extractCategoryTitles(skuConfiguration.categories);
    } else {
      return [];
    }
  }, [skuConfiguration]);

  const productIdComponent = useMemo(() => {
    if (displayProductId === DisplayEntityIdType.CustomerId) {
      const data = [{ label: 'Id', value: product.customerId }];

      if (!isSameId(product.id, product.customerId)) {
        data.push({ label: SYSTEM_ID_LABEL, value: product.id.toString() });
      }

      return data.map(item => (
        <ReadOnlyItem label={item.label} value={item.value ?? ''} key={item.value} />
      ));
    }
  }, [displayProductId, product.customerId, product.id]);

  /* Display only if product code should be displayed */
  const productCodeComponent = useMemo(() => {
    if (displayProductId === DisplayEntityIdType.Code) {
      return <ReadOnlyItem label="Code" value={product.code} />;
    }
  }, [displayProductId, product.code]);

  const brandComponent = useMemo(() => {
    const tmpBrand = brand ?? product.brand;
    const brandName = tmpBrand?.name;

    if (brandName) {
      return <ReadOnlyItem label="Brand" value={brandName} />;
    }
  }, [brand, product.brand]);

  const categoryComponent = useMemo(() => {
    return (
      <ReadOnlyItem label="Category">
        {isFetching ? (
          <Spin size="small" />
        ) : (
          <CategoryList categories={categoryNames} maxCategories={3} />
        )}
      </ReadOnlyItem>
    );
  }, [categoryNames, isFetching]);

  const header = useMemo(() => {
    const urlPath = generatePath(ROUTES.detail, {
      projectShortName: projectShortName ?? '',
      slug: selectedCategorySlug,
    });

    const productDetailUrl = `${urlPath}?product=${product.id}`;

    return (
      <Row $gap="1rem" $alignItems="center">
        <CalculationRedistributionSectionHeader content={product.name} link={productDetailUrl} />
        <Space split={splitter}>
          {productIdComponent}
          {productCodeComponent}
          {brandComponent}
          {categoryComponent}
        </Space>
      </Row>
    );
  }, [
    brandComponent,
    categoryComponent,
    product.id,
    product.name,
    productCodeComponent,
    productIdComponent,
    projectShortName,
    selectedCategorySlug,
  ]);

  return <Section header={header}></Section>;
};

export default CalculationRedistributionProductSummary;
