import React, { KeyboardEventHandler, useCallback, useEffect, useRef, useState } from 'react';
import { Checkbox, Form, Input, InputRef, Modal } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { Text } from '@ydistri/ds';
import { useSelectedTemplate } from '../../../hooks/useSelectedTemplate';
import { useCreateCalculationMutation } from '../../Calculations/apiCalculations';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '../../../components/menu/menuLeftItemTemplate';
import { createDebugLog } from '../../../lib/utils/logging';
import { useUser } from '../../../hooks/useUser';
import { CalculationMode, CalculationRequest, CalculationType } from '@ydistri/api-sdk';
import ValidateStoreDepartmentConfiguration from '../../Configuration/ConfigurationStoresAndDepartments/ValidateStoreDepartmentConfiguration';
import { ErrorType } from '../../../apis/api';
import { addToast } from '@ydistri/utils';
import { useDispatch, useSelector } from 'react-redux';
import OverlappingCalculationForm, { OVERLAP_TABLE_ID } from './OverlappingCalculationForm';
import { ReduxState } from '../../../store';
import CalculationModeSelect from './CalculationModeSelect';
import MergedCalculationTemplateSelect, {
  MergedCalculationData,
} from './TemplateMultiselectAndSort/MergedCalculationTemplateSelect';
import { TemplateSelect } from '../../Configuration/TemplateSelect';

const debugLog = createDebugLog('Configuration', 'CreateCalculationModal');

interface CreateCalculationModalProps {
  open: boolean;
  onCancel: () => void;
  displayTemplateSelect?: boolean;
}

interface FormFields {
  title: string;
  description: string;
  private: boolean;
}

const titleInputRules = [{ required: true, message: 'Please input the title of calculation' }];
const formLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 21 },
};

const CreateCalculationModal: React.FC<CreateCalculationModalProps> = ({
  open,
  onCancel,
  displayTemplateSelect = false,
}) => {
  const inputRef = useRef<InputRef>(null);
  const navigate = useNavigate();
  const selectedTemplate = useSelectedTemplate();
  const dispatch = useDispatch();

  const { projectShortName } = useParams();
  const [form] = Form.useForm<FormFields>();
  const [createCalculation, createCalculationStatus] = useCreateCalculationMutation();
  const user = useUser();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [mergedCalculationData, setMergedCalculationData] = useState<MergedCalculationData>();
  const [calculationMode, setCalculationMode] = useState<CalculationMode>(CalculationMode.Computed);
  const [templateId, setTemplateId] = React.useState<number | undefined>(
    displayTemplateSelect ? undefined : (selectedTemplate?.id ?? 0),
  );
  const templateChanged = useCallback((newTemplateId: number) => setTemplateId(newTemplateId), []);

  const selectedOverlappingCalculations = useSelector(
    (state: ReduxState) => state.calculations.selectedRowKeys[OVERLAP_TABLE_ID]?.arr,
  );

  useEffect(() => {
    if (calculationMode === CalculationMode.Merged) {
      let disableButton = false;
      const templateIds = mergedCalculationData?.sortedTemplateIds ?? [];
      if (
        templateIds.length === 0 ||
        templateIds.find(tid => mergedCalculationData?.calculationTitles[tid] === '')
      ) {
        disableButton = true;
      }
      setButtonDisabled(disableButton);
    } else if (calculationMode === CalculationMode.Computed && displayTemplateSelect) {
      setButtonDisabled(!templateId);
    }
  }, [
    calculationMode,
    displayTemplateSelect,
    mergedCalculationData?.calculationTitles,
    mergedCalculationData?.sortedTemplateIds,
    templateId,
  ]);

  const handleSubmit = useCallback(() => {
    form.validateFields().then(values => {
      const request: CalculationRequest = {
        title: values.title,
        description: values.description,
        calculationMode,
        templateId: [CalculationMode.Merged, CalculationMode.Manual].includes(calculationMode)
          ? undefined
          : templateId,
        overlappingCalculations: selectedOverlappingCalculations ?? [],
        subordinateCalculations:
          calculationMode === CalculationMode.Merged
            ? mergedCalculationData?.sortedTemplateIds.map((tid, i) => ({
                templateId: tid,
                title: `[${i + 1}] ${mergedCalculationData?.calculationTitles[tid] ?? ''}`,
                calculationMode: CalculationMode.Computed,
                calculationType: CalculationType.Submerged,
              }))
            : undefined,
        calculationType: values.private ? CalculationType.Private : CalculationType.Simulation,
      };

      createCalculation(request)
        .unwrap()
        .then(() => {
          form.resetFields();
          const path = generatePath(ROUTES.calculations, { projectShortName });
          debugLog('Should redirect to path:', path);
          navigate(path);
          onCancel();
        })
        .catch(error => {
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          const err = error as ErrorType;
          dispatch(
            addToast({
              message: `Calculation was not created: ${err.response.data.Messages?.join(', ')}`,
              isError: true,
            }),
          );
        });
    });
  }, [
    form,
    calculationMode,
    templateId,
    selectedOverlappingCalculations,
    mergedCalculationData?.sortedTemplateIds,
    mergedCalculationData?.calculationTitles,
    createCalculation,
    projectShortName,
    navigate,
    onCancel,
    dispatch,
  ]);

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(
    event => {
      if (event.key === 'Enter') {
        handleSubmit();
      }
    },
    [handleSubmit],
  );

  useEffect(() => {
    debugLog('useEffect', inputRef.current);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  return (
    <Modal
      data-type="modal"
      title="Create calculation"
      open={open}
      width={1000}
      okText="Create"
      okButtonProps={{
        disabled: buttonDisabled,
      }}
      onOk={handleSubmit}
      onCancel={onCancel}
      confirmLoading={createCalculationStatus.isLoading}
    >
      <Form
        data-type="create-calculation-form"
        form={form}
        labelCol={formLayout.labelCol}
        wrapperCol={formLayout.wrapperCol}
        name="create_calculation_modal"
        layout="horizontal"
      >
        <Form.Item name="title" label="Title" rules={titleInputRules}>
          <Input ref={inputRef} onKeyDown={handleKeyDown} />
        </Form.Item>
        <Form.Item name="description" label="Description">
          <TextArea rows={4} />
        </Form.Item>
        {user?.isSuperUser && (
          <Form.Item label="Type" name="private" valuePropName="checked">
            <Checkbox>Private calculation</Checkbox>
          </Form.Item>
        )}

        <Form.Item label="Mode">
          <CalculationModeSelect
            value={calculationMode}
            onValueChange={setCalculationMode}
            includeManualCalculation={user?.isSuperUser}
          />
        </Form.Item>
        {calculationMode === CalculationMode.Computed && (
          <Form.Item label="Template">
            {displayTemplateSelect ? (
              <TemplateSelect onChange={templateChanged} templateId={templateId} />
            ) : (
              <Text>
                #{selectedTemplate?.id} {selectedTemplate?.title}
              </Text>
            )}
          </Form.Item>
        )}
        {calculationMode === CalculationMode.Merged && (
          <Form.Item label="Templates">
            <MergedCalculationTemplateSelect onChange={setMergedCalculationData} />
          </Form.Item>
        )}

        {calculationMode !== CalculationMode.Manual && (
          <>
            <Form.Item name="overlappingCalculations" label="Overlapping">
              <OverlappingCalculationForm />
            </Form.Item>
            {!displayTemplateSelect && (
              <ValidateStoreDepartmentConfiguration setDisabledButton={setButtonDisabled} />
            )}
          </>
        )}
      </Form>
    </Modal>
  );
};

export default CreateCalculationModal;
