import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { styled } from 'styled-components';
import { Column, computeRemSize, Section } from '@ydistri/ds';
import { useTemplateOrCalculation } from '../../../../hooks/useTemplateOrCalculation';
import { useGetCalculationQuery } from '../../apiCalculationDetail';
import {
  useGetCalculationSkusQuery,
  useGetMonthlyForecastQuery,
  useGetMonthlySalesQuery,
  useGetSkuAttributesQuery,
} from '../../../Calculations/apiCalculationsSkus';
import SkuDetailActions from './SkuDetailActions';
import SkuDataComponent from './SkuAttributes/SkuDataComponent';
import {
  ForecastsObject,
  MinMaxForecast,
  SkuRedistributionPaneType,
  SkuRedistributionSubset,
} from '../redistributionTypes';
import CalculationRedistributionSectionHeader from '../CalculationRedistributionSectionHeader';
import SkuSaleChart from '../SkuSaleChart/SkuSaleChart';
import {
  AttributeDefinitionType,
  CalculationMode,
  ConfigurationFieldType,
  DisplayEntityIdType,
  OverviewValueType,
  SaleMonthlyOverviewResponse,
  TransactionOverviewType,
} from '@ydistri/api-sdk';
import { joinSalesAndForecast } from '../SkuSaleChart/SkuSaleChartLib';
import { getSkuFieldConfigurationNumericValue, parseSku } from '../redistributionLib';
import SkuInfoSource from './SkuAttributes/SkuInfoSource';
import { useSelectedTemplateId } from '../../../../hooks/useSelectedTemplateId';
import { useGetTemplateQuery } from '../../../Configuration/apiTemplate';
import SkuInfoTarget from './SkuAttributes/SkuInfoTarget';
import { useWindowDimension } from '../../../../hooks/useWindowDimension';
import { createDebugLog } from '../../../../lib/utils/logging';
import { useApplicationConfiguration } from '../../../../hooks/useApplicationConfiguration';
import { generatePath, useParams } from 'react-router-dom';
import { ROUTES } from '../../../../components/menu/menuLeftItemTemplate';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../../store';

interface SkuDetailSectionProps {
  redistributionData: SkuRedistributionSubset;
  infoType: SkuRedistributionPaneType;
  onShowSkuInfoChanged?: (showSkuInfo: boolean) => void;
}

const debugLog = createDebugLog('SkuDetailSection');

const SectionBody = styled.div`
  min-height: ${computeRemSize(300)};
  // max-height: calc(100vh - ${computeRemSize(370)});
`;

function getForecastForTypeFromMonthlyTransactions(
  monthlySales: SaleMonthlyOverviewResponse[],
  forecastType: TransactionOverviewType,
): number[] {
  const forecastsForType: number[] = [];
  let forecastCumulative = 0;

  monthlySales.forEach(sale => {
    const foundForecast = sale.overviews.filter(transactionValue => {
      return (
        transactionValue.overviewValueType === OverviewValueType.Quantity &&
        transactionValue.transactionOverviewType === forecastType
      );
    });
    if (foundForecast.length > 0) {
      forecastCumulative += foundForecast[0].value;
      forecastsForType.push(forecastCumulative);
    }
  });

  return forecastsForType;
}

const SkuDetailSection: React.FC<SkuDetailSectionProps> = ({
  redistributionData,
  infoType,
  onShowSkuInfoChanged,
}) => {
  const { displayStoreId } = useApplicationConfiguration();

  const calculationData = useTemplateOrCalculation();
  const { data: calculation } = useGetCalculationQuery(calculationData.id);

  const selectedTemplateId = useSelectedTemplateId();
  const { data: template } = useGetTemplateQuery(selectedTemplateId);

  const [showActualData, setShowActualData] = useState<boolean>(false);
  const [detailCumulativeForecast, setDetailCumulativeForecast] = useState<boolean>(false);
  const [showSkuInfo, setShowSkuInfo] = useState<boolean>(false);

  const windowDimension = useWindowDimension();

  const { projectShortName } = useParams();
  const selectedCategorySlug = useSelector(
    (state: ReduxState) => state.categoryTreeReducer.currentlySelectedCategorySlug,
  );

  /**
   * Make chart's height dynamic based on the window size.
   * Window is bigger on higher resolutions so the chart should have reasonable
   * height on all resolutions.
   * Minimum height is 290px.
   */
  const chartHeight = useMemo(() => {
    // 3.6 is the ratio of the chart height to the window height that is just about right
    const newHeight = Math.floor(windowDimension.height / 3.6);
    debugLog('new chart height: %d, window size is', newHeight, windowDimension);
    return Math.max(newHeight, 290);
  }, [windowDimension]);

  const { skuId } = redistributionData;

  const { data: skuConfiguration, isFetching: skuConfigurationFetching } =
    useGetCalculationSkusQuery({
      skuId: skuId,
      calculationId: calculationData.id,
    });

  const { data: skuAttributes, isFetching: skuAttributesFetching } = useGetSkuAttributesQuery({
    skuId,
    calculationId: calculationData.id,
    retrieveActualData: showActualData,
  });

  const { data: monthlySales } = useGetMonthlySalesQuery({
    skuId,
    calculationId: calculationData.id,
    monthsCount: 24,
  });

  const { data: monthlyForecast } = useGetMonthlyForecastQuery({
    skuId,
    calculationId: calculationData.id,
  });

  const skuData = useMemo(() => {
    debugLog(
      'computing skuData, skuAttributes fetched: %d, skuConfiguration fetched: %d',
      skuAttributesFetching ? 0 : 1,
      skuConfigurationFetching ? 0 : 1,
    );

    if (!(skuAttributesFetching || skuConfigurationFetching)) {
      if (skuConfiguration && skuAttributes) {
        const result = parseSku(skuConfiguration, skuAttributes);
        debugLog('skuData computed: %o', result);
        return result;
      }
    }
  }, [skuAttributesFetching, skuConfigurationFetching, skuConfiguration, skuAttributes]);

  /**
   * Use date from calculation or template based on the showActualData flag
   */
  const dateToUse: Date = useMemo(() => {
    if (showActualData) {
      if (template) {
        return new Date(template.applicationDate);
      }
    } else {
      if (calculation) {
        return new Date(calculation.applicationDate);
      }
    }
    return new Date();
  }, [calculation, showActualData, template]);

  useEffect(() => {
    if (onShowSkuInfoChanged) {
      onShowSkuInfoChanged(showSkuInfo);
    }
  }, [onShowSkuInfoChanged, showSkuInfo]);

  const chartData: SaleMonthlyOverviewResponse[] = useMemo(() => {
    if (
      monthlySales &&
      monthlyForecast &&
      skuConfiguration &&
      skuConfiguration.fieldConfigurations
    ) {
      console.log('MONTHLY FORECASTS: ', monthlyForecast);
      const minConfidence = getSkuFieldConfigurationNumericValue(
        skuConfiguration.fieldConfigurations,
        ConfigurationFieldType.PtMinForecastConfidence,
      );
      const maxConfidence = getSkuFieldConfigurationNumericValue(
        skuConfiguration.fieldConfigurations,
        ConfigurationFieldType.PsMaxForecastConfidence,
      );

      if (minConfidence && maxConfidence) {
        return joinSalesAndForecast(monthlySales, monthlyForecast, minConfidence, maxConfidence);
      }

      return monthlySales;
    }
    return [];
  }, [monthlyForecast, monthlySales, skuConfiguration]);

  const forecasts: ForecastsObject = useMemo(
    () => ({
      min: getForecastForTypeFromMonthlyTransactions(
        chartData,
        TransactionOverviewType.ForecastMin,
      ),
      max: getForecastForTypeFromMonthlyTransactions(
        chartData,
        TransactionOverviewType.ForecastMax,
      ),
    }),
    [chartData],
  );

  const usedForecast: MinMaxForecast = useMemo(() => {
    let min = 0;
    let max = 0;

    if (skuData && forecasts.min.length > 0 && forecasts.max.length > 0) {
      const availableSupply = skuData.attributes.values.AvailableSupply
        ? parseFloat(skuData.attributes.values.AvailableSupply)
        : 0;
      const psForecastMonths = parseInt(skuData.config.PsMonthsOfSupplyToKeep ?? '0');
      const ptForecastMonths = parseInt(skuData.config.PtMonthsOfSupplyToGetByForecast ?? '0');

      const forecastMax = psForecastMonths > 0 ? forecasts.max[psForecastMonths - 1] : 0;
      const forecastMin = ptForecastMonths > 0 ? forecasts.min[ptForecastMonths - 1] : 0;

      if (availableSupply >= 0 && forecastMax >= 0 && forecastMin >= 0) {
        max = parseFloat(
          forecastMax.toFixed(availableSupply === Math.round(availableSupply) ? 0 : 2),
        );
        min = parseFloat(
          forecastMin.toFixed(availableSupply === Math.round(availableSupply) ? 0 : 2),
        );
      }
    }

    return {
      min,
      max,
    };
  }, [forecasts, skuData]);

  const toggleActualData = useCallback(() => {
    setShowActualData(prevValue => !prevValue);
  }, []);

  const toggleCumulativeForecast = useCallback(() => {
    setDetailCumulativeForecast(prevValue => !prevValue);
  }, []);

  const toggleShowSkuInfo = useCallback(() => {
    setShowSkuInfo(prevValue => !prevValue);
  }, []);

  const manualCalculation = calculation?.mode === CalculationMode.Manual;

  const headerActions = useMemo(() => {
    return (
      <SkuDetailActions
        onShowActualData={toggleActualData}
        showActualData={showActualData}
        onToggleCumulativeForecast={toggleCumulativeForecast}
        cumulativeForecast={detailCumulativeForecast}
        onToggleShowSkuInfo={toggleShowSkuInfo}
        showSkuInfo={showSkuInfo}
        manualCalculation={manualCalculation}
      />
    );
  }, [
    manualCalculation,
    detailCumulativeForecast,
    showActualData,
    showSkuInfo,
    toggleActualData,
    toggleCumulativeForecast,
    toggleShowSkuInfo,
  ]);

  const sectionHeader = useMemo(() => {
    const { store } = redistributionData;

    //show code only when specified in the configuration
    let tmpStoreCode: string | undefined;
    if (displayStoreId === DisplayEntityIdType.Code) {
      tmpStoreCode = store.code ?? undefined;
    }

    //show customer id only when specified in the configuration
    let tmpCustomerId: string | null = null;
    if (displayStoreId === DisplayEntityIdType.CustomerId) {
      tmpCustomerId = store.customerStoreId ?? null;
    }

    const skuDetailPath = generatePath(ROUTES.detail, {
      projectShortName: projectShortName ?? '',
      slug: selectedCategorySlug,
    });

    const skuDetailUrl = `${skuDetailPath}?sku=${skuId}`;

    return (
      <CalculationRedistributionSectionHeader
        content={store.name}
        customerId={tmpCustomerId}
        systemId={store.id}
        skuId={skuData?.id}
        code={tmpStoreCode}
        link={skuDetailUrl}
      />
    );
  }, [
    displayStoreId,
    projectShortName,
    redistributionData,
    selectedCategorySlug,
    skuData?.id,
    skuId,
  ]);

  return (
    <Section
      header={sectionHeader}
      $scrollableContent={true}
      headerActions={headerActions}
      $ratio={1}
      $basis={1}
      $shrink={0}
      $backgroundColor={showActualData ? '#fffcf8' : undefined}
    >
      <SectionBody data-type="SkuDetailSectionBody">
        <Column $flexWrap="nowrap" $gap={computeRemSize(8)}>
          <SkuDataComponent
            infoType={infoType}
            skuData={skuData}
            redistributionData={redistributionData}
            showingActualData={showActualData}
            manualCalculation={manualCalculation}
          />

          {showSkuInfo && infoType === 'Source' && (
            <SkuInfoSource
              skuData={skuData}
              applicationDate={dateToUse}
              usedForecast={usedForecast}
              showActualData={showActualData}
            />
          )}
          {showSkuInfo && infoType === 'Target' && (
            <SkuInfoTarget
              skuData={skuData}
              applicationDate={dateToUse}
              usedForecast={usedForecast}
              showActualData={showActualData}
            />
          )}
          {monthlySales && calculation && (
            <SkuSaleChart
              applicationDate={new Date(calculation.applicationDate)}
              rawData={chartData}
              cumulativeMode={detailCumulativeForecast}
              height={chartHeight}
              vat={parseFloat(skuData?.attributes.values[AttributeDefinitionType.Vat] ?? '0')}
            />
          )}
        </Column>
      </SectionBody>
    </Section>
  );
};

export default SkuDetailSection;
