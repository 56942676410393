import { TargetListItemResponse, TargetListVersionConfigurationType } from '@ydistri/api-sdk';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
  computeRemSize,
  getDefaultSorting,
  InfiniteScrollTable,
  Panel,
  YColumnsType,
} from '@ydistri/ds';
import { TargetListSkusRequest, useGetTargetListSkusQuery } from '../../apiTargetLists';
import { getQuantityColumnHeader } from '../../targetListsLib';
import { useColumnWidth } from '../../../../../hooks/useColumnWidth';
import {
  EntityColumns,
  EntityColumnsConfig,
  useProductTableColumnsProvider,
  useStoreTableColumnsProvider,
} from '../../../../../hooks/useEntityTableColumnsProvider';

interface TargetListSkuGridProps {
  selectedTargetListId: number;
  configurationType: TargetListVersionConfigurationType;
  data?: TargetListItemResponse[];
  showActionType?: boolean;
  isInfinite?: boolean;
}

const BLOCK_SIZE: number = 45;

const TargetListSkuGrid: React.FC<TargetListSkuGridProps> = ({
  selectedTargetListId,
  configurationType,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [tableHeight, setTableHeight] = useState(480);
  const { productIdColumnWidth, storeIdColumnWidth } = useColumnWidth();

  const storeColumnsProvider = useStoreTableColumnsProvider();
  const productColumnsProvider = useProductTableColumnsProvider();

  const columns: YColumnsType<TargetListItemResponse>[] = useMemo(() => {
    const columnsConfig: EntityColumnsConfig<TargetListItemResponse> = {
      [EntityColumns.ID]: {
        width: storeIdColumnWidth,
        apiColumnName: 'Warehouse/CustomerWarehouseId',
      },
      [EntityColumns.NAME]: {
        width: '29rem',
        apiColumnName: 'Warehouse/Name',
        apiFilterable: true,
        apiFilterType: 'text',
        sorter: true,
      },
      [EntityColumns.CODE]: {
        width: '8rem',
        apiColumnName: 'Warehouse/Code',
        apiFilterable: true,
        apiFilterType: 'text',
        sorter: true,
      },
    };

    const storeColumns = storeColumnsProvider(
      columnsConfig,
      'warehouse',
      undefined,
      'customerWarehouseId',
    );

    const productColumnsConfig: EntityColumnsConfig<TargetListItemResponse> = {
      [EntityColumns.ID]: {
        apiFilterable: true,
        width: productIdColumnWidth,
      },
      [EntityColumns.CODE]: {
        apiFilterable: true,
        width: productIdColumnWidth,
      },
      [EntityColumns.NAME]: {
        apiFilterable: true,
        width: '29rem',
      },
    };
    const productColumns = productColumnsProvider(productColumnsConfig);

    const tmpColumns: YColumnsType<TargetListItemResponse>[] = [
      ...storeColumns,
      ...productColumns,
      {
        title: getQuantityColumnHeader(configurationType),
        key: 'quantity',
        dataIndex: 'quantity',
        width: '10rem',
        align: 'right',
        apiColumnName: 'Quantity',
        apiFilterable: true,
        apiFilterType: 'number',
        sorter: true,
      },
    ];

    return tmpColumns;
  }, [
    configurationType,
    productColumnsProvider,
    productIdColumnWidth,
    storeColumnsProvider,
    storeIdColumnWidth,
  ]);

  const defaultParams = useMemo(() => {
    return {
      targetListId: selectedTargetListId,
      skip: 0,
      top: BLOCK_SIZE,
      sortings: getDefaultSorting(columns),
    };
  }, [columns, selectedTargetListId]);

  const [params, setParams] = useState<TargetListSkusRequest>(defaultParams);

  useEffect(() => {
    setParams(defaultParams);
  }, [defaultParams]);

  const { data, isFetching, refetch } = useGetTargetListSkusQuery(params);

  const computeTableHeight = useCallback(() => {
    const TABLE_SHIFT: number = 96; //to compensate for the table's header as it is rendered outside the table's container
    if (ref.current) {
      setTableHeight(ref.current.clientHeight - TABLE_SHIFT);
    }
  }, []);

  useEffect(() => {
    //initial height
    computeTableHeight();

    //compute height on window resize
    window.addEventListener('resize', () => computeTableHeight);

    return () => {
      window.removeEventListener('resize', () => computeTableHeight);
    };
  }, [computeTableHeight, refetch]);

  const rowKeyProvider = useCallback(
    (row: TargetListItemResponse) =>
      row.skuId?.toString() ?? `${row.warehouse?.id}#${row.product?.id}`,
    [],
  );

  return (
    <Panel $noAutoHeight={true} $grow={1} ref={ref}>
      <InfiniteScrollTable<TargetListItemResponse, TargetListSkusRequest>
        id="targetListContent"
        height={computeRemSize(tableHeight)}
        setParams={setParams}
        columns={columns}
        dataSource={data}
        loading={isFetching}
        rowKey={rowKeyProvider}
      />
    </Panel>
  );
};

export default TargetListSkuGrid;
