import React, { useCallback, useMemo, useState } from 'react';
import { getSectionTitle } from '../../productListsLib';
import { GenericConfirmationModal, Section } from '@ydistri/ds';
import { ActiveScreenType } from '../../../common/administrationItemsLib';
import { useDispatch, useSelector } from 'react-redux';
import AdministrationItemActions from '../../../common/AdministrationItemActions';
import { addToast } from '../../../../../../../../../libs/utils/src/lib/toastSlice';
import {
  ProductListSetArchiveRequest,
  useDeleteProductListMutation,
  useGetProductListCalculationsQuery,
  useGetProductListsQuery,
  useSetProductListArchivedMutation,
} from '../../apiProductLists';
import { setSelectedProductList, setActiveScreenType } from '../../productListAdministrationSlice';
import ProductListProductsGrid from './ProductListProductsGrid';
import ProductListModal from '../../modals/ProductListModal';
import { ReduxState } from '../../../../../store';
import ProductListDetails from '../../components/ProductListDetails';
import { skipToken } from '@reduxjs/toolkit/query';
import { CalculationType } from '@ydistri/api-sdk';

const ProductListItemsSection: React.FC = () => {
  const dispatch = useDispatch();

  const selectedProductList = useSelector(
    (state: ReduxState) => state.productListsAdministration.selectedProductList,
  );
  const showArchivedItems = useSelector(
    (state: ReduxState) => state.productListsAdministration.showArchivedItems,
  );
  const [isBusy, setIsBusy] = useState(false);
  const [showDeleteProductListConfirmation, setShowDeleteProductListConfirmation] = useState(false);
  const [deleteProductList] = useDeleteProductListMutation();
  const [setArchived] = useSetProductListArchivedMutation();
  const [showEditModal, setShowEditModal] = useState(false);
  const { data: productLists } = useGetProductListsQuery(undefined);

  const { data: productListCalculations = [] } = useGetProductListCalculationsQuery(
    selectedProductList?.productListId ?? skipToken,
  );

  const hasTemplate = useMemo(
    () => productListCalculations.find(c => c.type === CalculationType.Template) !== undefined,
    [productListCalculations],
  );

  /**
   * Delete Product List action handler.
   * Display a confirmation modal.
   */
  const onProductListDelete = useCallback(() => {
    setShowDeleteProductListConfirmation(true);
  }, []);

  /**
   * Close the confirmation modal
   */
  const closeProductListDeleteConfirm = useCallback(() => {
    setShowDeleteProductListConfirmation(false);
  }, []);

  const onProductListContentEdit = useCallback(() => {
    dispatch(setActiveScreenType(ActiveScreenType.EDIT));
  }, [dispatch]);

  const onProductListArchiveToggle = useCallback(() => {
    if (!selectedProductList) {
      return;
    }

    if (!isBusy) {
      setIsBusy(true);

      const request: ProductListSetArchiveRequest = {
        productListId: selectedProductList.productListId,
        isArchived: !selectedProductList.isArchived,
      };

      setArchived(request)
        .unwrap()
        .then(updatedProductList => {
          if (!showArchivedItems) {
            dispatch(setSelectedProductList(undefined));
          } else {
            dispatch(setSelectedProductList(updatedProductList));
          }

          dispatch(
            addToast({
              message: `Product List was ${
                updatedProductList.isArchived ? 'archived' : 'unarchived'
              }`,
            }),
          );
        })
        .catch((error: Error) => {
          dispatch(
            addToast({
              isError: true,
              message: "Product List's archive status was not changed",
              description: error.message,
            }),
          );
        })
        .finally(() => {
          setIsBusy(false);
        });
    }
  }, [dispatch, isBusy, selectedProductList, setArchived, showArchivedItems]);

  const onProductListEdit = useCallback(() => setShowEditModal(true), []);
  const onEditProductListModalClose = useCallback(() => setShowEditModal(false), []);

  const onProductListDeleteConfirm = useCallback(() => {
    closeProductListDeleteConfirm();

    if (selectedProductList) {
      setIsBusy(true);
      if (!isBusy) {
        deleteProductList(selectedProductList.productListId)
          .unwrap()
          .then(() => {
            dispatch(setSelectedProductList());
          })
          .catch((error: Error) => {
            dispatch(
              addToast({
                isError: true,
                message: 'Product List was not deleted due to an error',
                description: error.message,
              }),
            );
          });
      }
    }
  }, [closeProductListDeleteConfirm, deleteProductList, dispatch, isBusy, selectedProductList]);

  const existingProductListNames = useMemo(
    () =>
      productLists
        ?.filter(productList => productList.productListId !== selectedProductList?.productListId)
        .map(productList => productList.name ?? ''),
    [productLists, selectedProductList],
  );

  /**
   * Actions for the header of the section
   */
  const headerActions = useMemo(() => {
    return (
      <AdministrationItemActions
        itemName="Product List"
        deletable={selectedProductList?.isDeletable}
        archivable={!hasTemplate}
        archived={selectedProductList?.isArchived}
        onDelete={onProductListDelete}
        onToggleArchived={onProductListArchiveToggle}
        onContentEdit={onProductListContentEdit}
        onEdit={onProductListEdit}
        disabled={isBusy}
      />
    );
  }, [
    selectedProductList?.isDeletable,
    selectedProductList?.isArchived,
    onProductListDelete,
    onProductListArchiveToggle,
    onProductListContentEdit,
    onProductListEdit,
    isBusy,
    hasTemplate,
  ]);

  if (!selectedProductList) {
    return null;
  } else {
    return (
      <>
        <Section header={getSectionTitle(selectedProductList)} headerActions={headerActions}>
          <ProductListDetails productList={selectedProductList} />
          <ProductListProductsGrid selectedProductListId={selectedProductList.productListId} />
        </Section>
        {showDeleteProductListConfirmation && (
          <GenericConfirmationModal
            data-testid="DeleteProductListConfirmationDialog"
            title="Delete Product List"
            message={`The Product List '${
              selectedProductList.name ?? '-no title-'
            }' will be deleted. Continue?`}
            onConfirmed={onProductListDeleteConfirm}
            onCanceled={closeProductListDeleteConfirm}
          />
        )}
        {showEditModal && (
          <ProductListModal
            productList={selectedProductList}
            onClose={onEditProductListModalClose}
            restrictedNames={existingProductListNames}
          />
        )}
      </>
    );
  }
};

export default ProductListItemsSection;
