import {
  ProductResponse,
  TargetListItemComparisonInformationResponse,
  TargetListVersionConfigurationType,
  WarehouseResponse,
} from '@ydistri/api-sdk';
import {
  AdministrationItemRow,
  AdministrationValidatedItem,
} from '../common/administrationItemsTypes';
import { ReactSelectType } from '../common/administrationItemsLib';

export interface TargetListInputData {
  storeId: string;
  productId: string;
  count: number;
}

export type TargetListRow = AdministrationItemRow<TargetListInputData>;

export interface SKUData {
  quantity: number;
  product: Pick<ProductResponse, 'customerId' | 'name' | 'code' | 'id'>;
  warehouse: Pick<WarehouseResponse, 'customerWarehouseId' | 'name' | 'code'>;
}

export interface TargetListItemsCompared {
  result?: TargetListItemComparisonInformationResponse;
  items: TargetListValidatedItem[];
}

export type TargetListValidatedItem = AdministrationValidatedItem<TargetListInputData, SKUData>;

export const targetListTypeOptions: ReactSelectType<TargetListVersionConfigurationType>[] = [
  {
    label: 'Defined quantity will be transferred to target store',
    value: TargetListVersionConfigurationType.ReceiveDefinedQuantity,
  },
  {
    label: 'Target store will be supplied up to defined quantity',
    value: TargetListVersionConfigurationType.TopUpDefinedQuantity,
  },
];
