import CalculationRedistributionSection from './CalculationRedistributionSection';
import { Row } from '@ydistri/ds';
import CalculationRedistributionProductSummary from './CalculationRedistributionProductSummary';
import CalculationRedistributionSourceSection from './CalculationRedistributionSourceSection';
import CalculationRedistributionSummary from './CalculationRedistributionSummary';
import CalculationRedistributionTargetSection from './CalculationRedistributionTargetSection';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../store';
import { createDebugLog } from '../../../lib/utils/logging';
import { useWindowDimension } from '../../../hooks/useWindowDimension';

const debugLog = createDebugLog('CalculationRedistributionSubpageContent');

interface CalculationRedistributionSubpageContentProps {
  calculationId: number;
}

const CalculationRedistributionSubpageContent: React.FC<
  CalculationRedistributionSubpageContentProps
> = ({ calculationId }) => {
  const selectedSKURedistribution = useSelector(
    (state: ReduxState) => state.calculations.selectedSKURedistribution,
  );
  const windowDimensions = useWindowDimension();

  const [sourcePaneExpanded, setSourcePaneExpanded] = useState(false);
  const [targetPaneExpanded, setTargetPaneExpanded] = useState(false);

  const height = useMemo(() => {
    const expanded = sourcePaneExpanded || targetPaneExpanded;
    const otherElementsHeight = expanded ? 955 + 400 : 955;
    const newHeight = selectedSKURedistribution
      ? `${Math.max(windowDimensions.height - otherElementsHeight, 190)}px`
      : 'auto';

    debugLog(
      'computing Redistribution table height, sku is expanded: %s, new height: %s',
      expanded.toString(),
      newHeight,
    );

    return newHeight;
  }, [selectedSKURedistribution, sourcePaneExpanded, targetPaneExpanded, windowDimensions.height]);

  return (
    <>
      <CalculationRedistributionSection $height={height} $minHeight={height} />
      {selectedSKURedistribution && (
        <>
          <Row>
            <CalculationRedistributionProductSummary
              product={selectedSKURedistribution.product}
              skuId={selectedSKURedistribution.sourceSkuId}
              brand={selectedSKURedistribution.brand}
              calculationId={calculationId}
            />
          </Row>
          <Row $flexWrap="nowrap">
            <CalculationRedistributionSourceSection onShowSkuInfoChanged={setSourcePaneExpanded} />
            <CalculationRedistributionSummary />
            <CalculationRedistributionTargetSection onShowSkuInfoChanged={setTargetPaneExpanded} />
          </Row>
        </>
      )}
    </>
  );
};

export default CalculationRedistributionSubpageContent;
