import React, { useCallback, useEffect, useMemo } from 'react';
import { EntityListImportAction, EntityListType } from '@ydistri/api-sdk';
import {
  useDeleteEntityListImportMutation,
  useGetEntityListImportSummaryQuery,
  usePutEntityListItemsMutation,
} from '../../apiEntityLists';
import { QueryStatus } from '@reduxjs/toolkit/query';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../../../store';
import StatisticsItemComponent from '../../../common/validation-results/StatisticsItemComponent';
import { Button, Space } from 'antd';
import { StatisticsItem, StatisticsItemType } from '../../../common/administrationItemsTypes';
import { setSelectedValidationImportAction } from '../../entityListAdministrationSlice';
import EntityListItemImportGrid from './EntityListItemImportGrid';
import { ActionsBar, Column, Text } from '@ydistri/ds';
import { IMPORT_STEPS, titleByEntityListType } from '../../entityListsLib';
import { useSetImportStep } from '../../useSetImportStep';
import { useSetImportStepRunning } from '../../useSetImportStepRunning';
import { addToast } from '../../../../../../../../../libs/utils/src/lib/toastSlice';

interface EntityListStepValidationProps {
  entityListType: EntityListType;
}

type ImportActionMapValue = Pick<StatisticsItem, 'label' | 'type'> & { position: number };
const importActionMap: Record<EntityListImportAction, ImportActionMapValue> = {
  [EntityListImportAction.NotSet]: {
    label: 'Not set',
    type: StatisticsItemType.ERROR,
    position: 8,
  },
  [EntityListImportAction.Skip]: {
    label: 'Skip',
    type: StatisticsItemType.ERROR,
    position: 2,
  },
  [EntityListImportAction.Insert]: {
    label: 'New records',
    type: StatisticsItemType.SUCCESS,
    position: 3,
  },
  [EntityListImportAction.Update]: {
    label: 'Update',
    type: StatisticsItemType.SUCCESS,
    position: 5,
  },
  [EntityListImportAction.CreateSkuAndInsert]: {
    label: 'New SKU',
    type: StatisticsItemType.SUCCESS,
    position: 4,
  },
  [EntityListImportAction.Duplicate]: {
    label: 'Duplicates',
    type: StatisticsItemType.ERROR,
    position: 6,
  },
  [EntityListImportAction.NoChange]: {
    label: 'No change',
    type: StatisticsItemType.UNCHANGED,
    position: 7,
  },
  [EntityListImportAction.Delete]: {
    label: 'Remove',
    type: StatisticsItemType.REMOVED,
    position: 1,
  },
};

const convertImportActionToStatisticsItem = (
  a: EntityListImportAction,
  count: number,
): StatisticsItem => {
  return {
    observation: count,
    label: importActionMap[a].label,
    type: importActionMap[a].type,
  };
};

const EntityListStepValidation: React.FC<EntityListStepValidationProps> = ({ entityListType }) => {
  const setStep = useSetImportStep(entityListType);
  const setStepStatus = useSetImportStepRunning(entityListType);
  const [putEntityListItems, { status: putStatus }] = usePutEntityListItemsMutation();
  const [deleteEntityListImport, { status: deleteStatus }] = useDeleteEntityListImportMutation();
  const dispatch = useDispatch();
  const selectedEntityList = useSelector(
    (state: ReduxState) => state.entityListsAdministration[entityListType].selectedEntityList,
  );

  const entityListId = selectedEntityList?.entityListId;
  const verificationTimeStamp = useSelector(
    (state: ReduxState) => state.entityListsAdministration[entityListType].verificationTimeStamp,
  );

  const selectedValidationImportAction = useSelector(
    (state: ReduxState) =>
      state.entityListsAdministration[entityListType].selectedValidationImportAction,
  );

  const { data: entityListImportSummary, isLoading: isLoadingSummary } =
    useGetEntityListImportSummaryQuery(selectedEntityList?.entityListId ?? 0);

  const clickHandler = useCallback(
    (stat: EntityListImportAction) => {
      return () => dispatch(setSelectedValidationImportAction({ entityListType, data: stat }));
    },
    [dispatch, entityListType],
  );

  const summaries = useMemo(
    () =>
      (entityListImportSummary ?? [])
        .filter(stat => stat.itemCount > 0)
        .toSorted(
          (a, b) =>
            importActionMap[a.entityListImportAction].position -
            importActionMap[b.entityListImportAction].position,
        ),
    [entityListImportSummary],
  );

  useEffect(() => {
    if (!isLoadingSummary) {
      if (summaries.length > 0) {
        dispatch(
          setSelectedValidationImportAction({
            entityListType,
            data: summaries[0].entityListImportAction,
          }),
        );
      }
    }
  }, [dispatch, summaries, entityListType, isLoadingSummary]);

  const entityListEmpty = useMemo(() => {
    let sum = 0;
    (entityListImportSummary ?? []).forEach(is => {
      if (
        [
          EntityListImportAction.Update,
          EntityListImportAction.Insert,
          EntityListImportAction.CreateSkuAndInsert,
          EntityListImportAction.NoChange,
        ].includes(is.entityListImportAction)
      )
        sum += is.itemCount;
    });
    return sum === 0;
  }, [entityListImportSummary]);

  const sendHandler = useCallback(() => {
    setStepStatus(IMPORT_STEPS.FINALIZATION, true);
    if (entityListId && verificationTimeStamp) {
      putEntityListItems({
        entityListId,
        verificationTimeStamp,
      })
        .then(() => {
          setStep(IMPORT_STEPS.FINALIZATION);
          dispatch(
            addToast({
              isError: false,
              message: `${titleByEntityListType[entityListType]} was successfully finalized! You can now use it.`,
            }),
          );
        })
        .catch(() => {
          dispatch(
            addToast({
              isError: true,
              message: `Finalization of ${titleByEntityListType[entityListType]} was unsuccessful. Please refresh the page and try again or contact the administrator.`,
            }),
          );
        })
        .finally(() => {
          setStepStatus(IMPORT_STEPS.FINALIZATION, false);
        });
    }
  }, [
    dispatch,
    entityListId,
    entityListType,
    putEntityListItems,
    setStep,
    setStepStatus,
    verificationTimeStamp,
  ]);

  const deleteHandler = useCallback(() => {
    if (entityListId && verificationTimeStamp) {
      setStepStatus(IMPORT_STEPS.ITEM_VALIDATION, true);
      deleteEntityListImport({
        entityListId,
        entityListType,
        verificationTimeStamp,
      })
        .then(() => {
          setStep(IMPORT_STEPS.ITEM_IMPORT);
          dispatch(
            addToast({
              isError: false,
              message: `Import of ${titleByEntityListType[entityListType]} was canceled.`,
            }),
          );
        })
        .catch(() => {
          dispatch(
            addToast({
              isError: true,
              message: `Cancellation of ${titleByEntityListType[entityListType]} import was unsuccessful. Please refresh the page and try again or contact the administrator.`,
            }),
          );
        })
        .finally(() => {
          setStepStatus(IMPORT_STEPS.ITEM_VALIDATION, false);
        });
    }
  }, [
    deleteEntityListImport,
    dispatch,
    entityListId,
    entityListType,
    setStep,
    setStepStatus,
    verificationTimeStamp,
  ]);

  const mutationRunning = putStatus === QueryStatus.pending || deleteStatus === QueryStatus.pending;

  return (
    <Column $gap="0.5rem">
      {entityListImportSummary ? (
        <Space>
          {summaries.map(stat => (
            <StatisticsItemComponent
              onClick={clickHandler(stat.entityListImportAction)}
              item={convertImportActionToStatisticsItem(
                stat.entityListImportAction,
                stat.itemCount,
              )}
              selected={stat.entityListImportAction === selectedValidationImportAction}
              key={stat.entityListImportAction}
            />
          ))}
        </Space>
      ) : null}
      {selectedValidationImportAction && selectedEntityList?.entityListId ? (
        <EntityListItemImportGrid
          entityListType={entityListType}
          selectedEntityListId={selectedEntityList.entityListId}
        />
      ) : null}
      {entityListEmpty ? (
        <Text $type="danger">Entity list would be empty, it is not possible to finalize.</Text>
      ) : null}
      <ActionsBar data-testid="EntityListEditorActions">
        <Button
          disabled={
            !selectedEntityList || mutationRunning || !verificationTimeStamp || entityListEmpty
          }
          data-testid="put-entity-list-items"
          type="primary"
          onClick={sendHandler}
          loading={putStatus === QueryStatus.pending}
        >
          Finalize {titleByEntityListType[entityListType]}
        </Button>
        <Button
          disabled={!selectedEntityList || mutationRunning || !verificationTimeStamp}
          data-testid="delete-entity-list-import"
          type="default"
          onClick={deleteHandler}
          loading={deleteStatus === QueryStatus.pending}
        >
          Cancel import
        </Button>
      </ActionsBar>
    </Column>
  );
};

export default EntityListStepValidation;
