import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { computeRemSize, getDefaultSorting, Panel, YColumnsType } from '@ydistri/ds';
import {
  EntityListImportAction,
  EntityListImportItemResponse,
  EntityListType,
} from '@ydistri/api-sdk';
import { useColumnWidth } from '../../../../../hooks/useColumnWidth';
import {
  GetEntityListImportItemsRequest,
  useGetEntityListImportItemsQuery,
} from '../../apiEntityLists';
import { entityListTypesWithProducts, entityListTypesWithStores } from '../../entityListsLib';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../../../store';
import { SimpleTable } from '../../../../CalculationDetail/Statistics/components/SimpleTable';
import {
  EntityColumns,
  EntityColumnsConfig,
  useProductTableColumnsProvider,
  useStoreTableColumnsProvider,
} from '../../../../../hooks/useEntityTableColumnsProvider';

interface EntityListItemImportGridProps {
  entityListType: EntityListType;
  selectedEntityListId: number;
}

const BLOCK_SIZE: number = 45;

const EntityListItemImportGrid: React.FC<EntityListItemImportGridProps> = ({
  entityListType,
  selectedEntityListId,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const { productIdColumnWidth, storeIdColumnWidth } = useColumnWidth();
  const [tableHeight, setTableHeight] = useState(480);

  const selectedValidationImportAction = useSelector(
    (state: ReduxState) =>
      state.entityListsAdministration[entityListType].selectedValidationImportAction,
  );

  const storeColumnsProvider = useStoreTableColumnsProvider();
  const productColumnsProvider = useProductTableColumnsProvider();

  const columns: YColumnsType<EntityListImportItemResponse>[] = useMemo(() => {
    const tmpColumns: YColumnsType<EntityListImportItemResponse>[] = [];

    if (entityListTypesWithProducts.includes(entityListType)) {
      const productColumnsConfig: EntityColumnsConfig<EntityListImportItemResponse> = {
        [EntityColumns.ID]: {
          width: productIdColumnWidth,
          render: (value: string, record) =>
            !value || value === '' ? record.productIdentification : value,
        },
        [EntityColumns.NAME]: {
          width: '29rem',
          apiFilterable: true,
        },
        [EntityColumns.CODE]: {
          width: '6rem',
          apiFilterable: true,
        },
      };
      const productColumns =
        productColumnsProvider<EntityListImportItemResponse>(productColumnsConfig);

      tmpColumns.push(...productColumns);
    }

    if (entityListTypesWithStores.includes(entityListType)) {
      const storeColumnsConfig: EntityColumnsConfig<EntityListImportItemResponse> = {
        [EntityColumns.ID]: {
          width: storeIdColumnWidth,
          render: (value: string, record) =>
            !value || value === '' ? record.storeIdentification : value,
        },
        [EntityColumns.NAME]: {
          width: '29rem',
        },
        [EntityColumns.CODE]: {
          width: '10rem',
        },
      };
      const storeColumns = storeColumnsProvider<EntityListImportItemResponse>(storeColumnsConfig);
      tmpColumns.push(...storeColumns);

      tmpColumns.push({
        title: 'Quantity',
        key: 'quantity',
        dataIndex: 'quantity',
        width: '10rem',
        align: 'right',
        apiColumnName: 'Quantity',
        apiFilterable: true,
        apiFilterType: 'number',
        sorter: true,
      });
    }

    return tmpColumns;
  }, [
    entityListType,
    productColumnsProvider,
    productIdColumnWidth,
    storeColumnsProvider,
    storeIdColumnWidth,
  ]);

  const defaultParams: GetEntityListImportItemsRequest = useMemo(() => {
    return {
      entityListId: selectedEntityListId,
      entityListImportAction: selectedValidationImportAction ?? EntityListImportAction.Insert,
      skip: 0,
      top: BLOCK_SIZE,
      sortings: getDefaultSorting(columns),
    };
  }, [selectedValidationImportAction, columns, selectedEntityListId]);

  const [params, setParams] = useState<GetEntityListImportItemsRequest>(defaultParams);

  useEffect(() => {
    // eslint-disable-next-line @nx/workspace/no-useless-setstate-in-effect
    setParams(defaultParams);
  }, [selectedValidationImportAction, defaultParams]);

  const { data, isFetching, refetch } = useGetEntityListImportItemsQuery(params);

  const resizeTable = useCallback(() => {
    const TABLE_SHIFT: number = 96;
    if (ref.current) {
      setTableHeight(ref.current.clientHeight - TABLE_SHIFT);
    }
  }, []);

  useEffect(() => {
    resizeTable();

    //compute height on window resize
    window.addEventListener('resize', () => resizeTable);

    return () => {
      window.removeEventListener('resize', () => resizeTable);
    };
  }, [refetch, resizeTable]);

  const rowKeyProvider = useCallback(
    (row: EntityListImportItemResponse) =>
      row.sku?.skuId
        ? `sku-${row.sku.skuId}`
        : `s-${row.store?.id ?? row.storeIdentification}-p-${row.product?.id ?? row.productIdentification}`,
    [],
  );

  return (
    <Panel $height={computeRemSize(tableHeight)} ref={ref}>
      <SimpleTable<EntityListImportItemResponse>
        id="entityListImportItemsContent"
        height={computeRemSize(tableHeight)}
        columns={columns}
        dataSource={data}
        loading={isFetching}
        rowKey={rowKeyProvider}
        minRowHeight="30px"
        showHeader={true}
      />
    </Panel>
  );
};

export default EntityListItemImportGrid;
