import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react';
import { ApiTags } from './apiLib';
import { API } from '../swagger/collections';

export const APITAGS = {
  application: {
    projects: 'Projects',
    templates: 'Templates',
    applicationData: 'ApplicationData',
    applicationHints: 'ApplicationHints',
    categoryConfigurationDefinitions: 'CategoryConfigurationDefinitions',
    currentSetup: 'CurrentSetup',
  },
  globalConfiguration: {
    calculationConfiguration: 'CalculationConfiguration',
  },
  regionFlowGroups: {
    regionFlowGroups: 'RegionFlowGroups',
  },
  calculationDetail: {
    calculation: 'Calculation',
  },
  calculations: {
    calculations: 'Calculations',
    calculationStatistics: 'CalculationStatistics',
  },
  calculationStatistics: {
    calculationStats: 'CalculationStats',
    redistributionRowCount: 'RedistributionRowCount',
  },
  calculationStatistics2: {
    calculationStats: 'CalculationStats',
    statsRouteSummaries: 'StatsRouteSummaries',
    statsRoutes: 'StatsRoutes',
    statsStoreSummaries: 'StatsStoreSummaries',
    statsSourceStores: 'StatsSourceStores',
    statsTargetStores: 'StatsTargetStores',
    statsRegions: 'StatsRegions',

    statsTargetListSummary: 'StatsTargetListSummary',
    statsTargetListItems: 'StatsTargetListItems',
    statsTargetListItemSources: 'StatsTargetListItemSources',
  },
  calculationEvaluations: {
    calculationEvaluationSummary: 'CalculationEvaluationSummary',
    calculationFulfillmentSummary: 'CalculationFulfillmentSummary',
    calculationStoreExecutionRates: 'CalculationStoreExecutionRates',
    calculationStoreFulfillmentRates: 'CalculationStoreFulfillmentRates',
    calculationSourceStoreTargetStoresExecutionRates:
      'CalculationSourceStoreTargetStoresExecutionRates',
  },
  calculationPickingResults: {
    calculationPickingResultsSummary: 'CalculationPickingResultsSummary',
    calculationStorePickingResults: 'CalculationStorePickingResults',
    calculationStoreTargetStoresPickingResults: 'CalculationStoreTargetStoresPickingResults',
    calculationPickingInsights: 'CalculationPickingInsights',
    calculationStoreTargetStorePickingInsights: 'CalculationStoreTargetStorePickingInsights',
    calculationPickingInsightsSummary: 'CalculationPickingInsightsSummary',
    calculationPickingIssues: 'CalculationPickingIssues',
    calculationSourceStorePickingIssues: 'CalculationSourceStorePickingIssues',
    calculationPickingIssuesDetails: 'CalculationPickingIssuesDetails',
  },
  balancingConfiguration: {
    balancingConfiguration: 'BalancingConfiguration',
  },
  categoryTree: {
    categoryTree: 'CategoryTree',
  },
  template: {
    categoryConfiguration: 'CategoryConfiguration',
    template: 'Template',
    overlappingCalculations: 'TemplateOverlappingCalculations',
  },
  targetListConfiguration: {
    targetListConfiguration: 'TargetListConfiguration',
    availableTargetLists: 'AvailableTargetLists',
  },
  targetLists: {
    targetListResponse: 'TargetListResponse',
    targetListSkuResponse: 'TargetListSkuResponse',
    targetListCalculations: 'TargetListCalculations',
  },
  entityListsAdministration: {
    entityList: 'EntityList',
    entityListsList: 'EntityListsList',
    entityListItems: 'EntityListItems',
    entityListCalculations: 'EntityListCalculations',
    entityListImportSummary: 'EntityListImportSummary',
    entityListImportItems: 'EntityListImportItems',
  },
  entityListsAdmin: {
    entityListResponse: 'EntityListResponse',
    entityListSkuResponse: 'EntityListSkuResponse',
    entityListCalculations: 'EntityListCalculations',
  },
  skuClassConfiguration: {
    skuClassConfiguration: 'SkuClassConfiguration',
  },
  brandsConfiguration: {
    brandsConfiguration: 'BrandsConfiguration',
    brandProducts: 'BrandProducts',
  },
  productListConfiguration: {
    productListConfiguration: 'ProductListConfiguration',
    productListConfigurationProducts: 'ProductListConfigurationProducts',
  },
  storeTypeConfiguration: {
    storeTypeConfiguration: 'StoreTypeConfiguration',
  },
  storeConfiguration: {
    storeConfiguration: 'StoreConfiguration',
    currentSetupStoreConfiguration: 'CurrentSetupStoreConfiguration',
  },
  productLists: {
    productLists: 'ProductLists',
    productListItems: 'ProductListItems',
    productListCalculations: 'ProductListCalculations',
  },
  calculationCategories: {
    // calculationCategories: 'CalculationCategories',
    calculationCategoryPairings: 'CalculationsCategoryPairings',
  },
  calculationSkus: {
    calculationSkus: 'CalculationSkus',
    calculationSkuMonthlySales: 'CalculationSkuMonthlySales',
    calculationSkuMonthlyForecast: 'CalculationSkuMonthlyForecast',
    skuAttributes: 'SkuAttributes',
  },
  drillDown: {
    categoryOverviewForDrilldown: 'CategoryOverviewForDrilldown',
  },
  identity: {},
  detail: {
    products: 'Products',
    stores: 'Stores',
    skuClasses: 'SkuClasses',
    skus: 'Skus',
    skuEntityLists: 'skuEntityLists',
    skuCalculationsSource: 'SkuCalculationsSource',
    skuCalculationsTarget: 'SkuCalculationsTarget',
  },
  lists: {
    brandsList: 'BrandsList',
    regionsList: 'RegionsList',
    storesList: 'StoresList',
    storeTypesList: 'StoreTypesList',
    departmentsList: 'DepartmentsList',
  },
  categoryInsights: {
    categoryInsights: 'CategoryInsights',
  },
  scopes: {
    scopes: 'Scopes',
    finalScopeConfigurations: 'FinalScopeConfigurations',
    regionList: 'RegionList',
    storeList: 'StoreList',
    storeTypeList: 'StoreTypeList',
    departmentList: 'DepartmentList',
  },
} as const;

export const apiConfigurationTags: ApiTags[] = [
  APITAGS.categoryTree.categoryTree,
  APITAGS.template.categoryConfiguration,
  APITAGS.regionFlowGroups.regionFlowGroups,
  APITAGS.storeConfiguration.storeConfiguration,
  APITAGS.storeTypeConfiguration.storeTypeConfiguration,
  APITAGS.skuClassConfiguration.skuClassConfiguration,
  APITAGS.productListConfiguration.productListConfiguration,
  APITAGS.brandsConfiguration.brandsConfiguration,
  APITAGS.globalConfiguration.calculationConfiguration,
  APITAGS.balancingConfiguration.balancingConfiguration,
  APITAGS.scopes.scopes,
  APITAGS.scopes.finalScopeConfigurations,
  APITAGS.scopes.regionList,
  APITAGS.scopes.storeList,
  APITAGS.scopes.storeTypeList,
  APITAGS.scopes.departmentList,
];

export const getAllTags = (): ApiTags[] => {
  return Object.values(APITAGS)
    .map(group => Object.values(group))
    .flat();
};

export const getAllTagsExcept = (tagsToRemove: ApiTags[]): ApiTags[] => {
  return getAllTags().filter(tag => !tagsToRemove.includes(tag));
};

export type TemplateOrCalculationType = 'Template' | 'Calculation';

export interface TemplateOrCalculation {
  type: TemplateOrCalculationType;
  id: number;
}

export const wrongTemplateInHeaders = (templateOrCalculation: TemplateOrCalculation): boolean =>
  templateOrCalculation.type === 'Template' &&
  API.instance.defaults.headers.common['Template-Id'] !== templateOrCalculation.id;

export interface ErrorType {
  code: string;
  message: string;
  response: {
    data: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Messages: string[];
    };
    status: number;
    statusText: string;
  };
}

export const errorTransform = (e: ErrorType): ErrorType => ({
  code: e.code,
  message: e.message,
  response: {
    data: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Messages: e.response.data.Messages,
    },
    status: e.response.status,
    statusText: e.response.statusText,
  },
});

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fakeBaseQuery<ErrorType>(),
  tagTypes: getAllTags(),
  endpoints: () => ({}),
});
