import { ColumnsType, ColumnType } from 'antd/es/table';
import {
  CalculationPickingIssuesResponse,
  CalculationStatisticStoreSourceResponse,
  PapRedistributionIssue,
  PickingIssue,
  PickingIssueResponse,
  StorePickingInsightsResponse,
} from '@ydistri/api-sdk';
import { computeRemSize, OutlierValue, YColumnsType } from '@ydistri/ds';
import { formatNumber } from '@ydistri/utils';
import { isOutlier } from './pickingIssuesUtilsLib';

export type PickingIssuesData = CalculationStatisticStoreSourceResponse &
  CalculationPickingIssuesResponse &
  Pick<StorePickingInsightsResponse, 'executedQuantity' | 'executedValue' | 'skuCount'>;

const outlierValueTooltip = 'An exceptionally different value from the rest';

/** Mapping of the issue type to the corresponding string for UI */
const issuesMap: { [key in PapRedistributionIssue]: string } = {
  [PapRedistributionIssue.DamagedOrExpired]: 'Damaged / Expired',
  [PapRedistributionIssue.NotSending]: 'Not Sending More',
  [PapRedistributionIssue.NotFound]: 'Not Found',
  [PapRedistributionIssue.Other]: 'Other',
  [PapRedistributionIssue.DifferentSupply]: 'Different Supply',
  [PapRedistributionIssue.SkipTemporarily]: 'Skipped Temporarily',
  [PapRedistributionIssue.SkipPermanently]: 'Skipped Permanently',
  [PapRedistributionIssue.Damaged]: 'Damaged',
  [PapRedistributionIssue.Expired]: 'Expired',
  [PapRedistributionIssue.Sale]: 'Sale',
  [PapRedistributionIssue.NotInPlanogram]: 'Not In Planogram',
};

//map of columns for each of the redistribution types
const issueColumnsMap = new Map<PapRedistributionIssue, ColumnType<PickingIssuesData>>();

/**
 * Translate the issue type to the corresponding string for UI
 * @param issue
 */
export const translateRedistributionIssue = (issue: PapRedistributionIssue): string => {
  return issuesMap[issue] || 'Unknown issue';
};

/**
 * Sorts issues by translated title alphabetically, type Other is always last as it means none of the others
 * @param left
 * @param right
 */
const issueTypeSorter = (left: PapRedistributionIssue, right: PapRedistributionIssue): number => {
  if (left === PapRedistributionIssue.Other) {
    return 1;
  }

  if (right === PapRedistributionIssue.Other) {
    return -1;
  }

  return translateRedistributionIssue(left).localeCompare(translateRedistributionIssue(right));
};
/**
 * Combine the data from the calculation statistic, picking issues and picking insights to one object
 * that can be used in the table component
 * @param stats
 * @param issues
 * @param pickingInsights
 */
export const preparePickingIssuesData = (
  stats: CalculationStatisticStoreSourceResponse[],
  issues: CalculationPickingIssuesResponse[],
  pickingInsights: StorePickingInsightsResponse[],
): PickingIssuesData[] => {
  return issues.map(issue => {
    const matchedStat = stats.find(stat => stat.sourceStore.id === issue.store.id);
    const matchedInsight = pickingInsights.find(insight => insight.store.id === issue.store.id);

    const result: PickingIssuesData = {
      store: issue.store,
      sourceStore: matchedStat ? matchedStat.sourceStore : issue.store,
      issues: issue.issues,
      value: matchedStat ? matchedStat.value : 0,
      quantity: matchedStat ? matchedStat.quantity : 0,
      productCount: matchedStat ? matchedStat.productCount : 0,
      pickingPositionCount: matchedStat ? matchedStat.pickingPositionCount : 0,
      targetStoreCount: matchedStat ? matchedStat.targetStoreCount : 0,
      count: issue.count,
      executedQuantity: matchedInsight ? matchedInsight.executedQuantity : 0,
      executedValue: matchedInsight ? matchedInsight.executedValue : 0,
      skuCount: matchedInsight ? matchedInsight.skuCount : 0,
    };

    return result;
  });
};

/**
 * Get the number of issues of a specific type from the list of issues
 * @param issues
 * @param issueType
 */
const getIssueCount = (
  issues: PickingIssue[],
  issueType: PapRedistributionIssue,
): number | undefined => {
  const issue = issues.find(issue => issue.papRedistributionIssueId === issueType);
  if (issue) {
    return issue.count;
  }
};

/**
 * Render the cell for a specific issue type.
 * If the issue is an outlier, the value will be displayed in a different style to draw attention to it.
 * @param record
 * @param issueType
 */
const issueCellRenderer = (
  record: CalculationPickingIssuesResponse,
  issueType: PapRedistributionIssue,
) => {
  const value = getIssueCount(record.issues, issueType);
  if (value) {
    const allValues = record.issues.map(issue => issue.count);
    const outlier = isOutlier(allValues, value);
    return outlier ? <OutlierValue value={value} tooltip={outlierValueTooltip} center /> : value;
  }
};

/**
 * Generate a column for given issue type.
 * Columns for issue type have the same properties and behave the same way
 * @param issueType
 */
const generateIssueColumn = (issueType: PapRedistributionIssue): ColumnType<PickingIssuesData> => {
  return {
    title: translateRedistributionIssue(issueType),
    key: issueType.toString(),
    align: 'center',
    width: computeRemSize(100),
    render: (record: CalculationPickingIssuesResponse) => issueCellRenderer(record, issueType),
  };
};

/**
 * Generate a map of columns for issue types
 * Clears the previous columns if the map is not empty
 */
const generateIssueColumnsMap = () => {
  if (issueColumnsMap.size > 0) {
    issueColumnsMap.clear();
  }

  Object.values(PapRedistributionIssue).forEach(issueType => {
    issueColumnsMap.set(issueType, generateIssueColumn(issueType));
  });
};

/**
 * Generate the columns for the picking issues table
 * @param data
 * @param currency
 * @param storeColumns The columns for the store
 */
export const getPickingIssuesColumns = (
  data: PickingIssuesData[],
  currency: string,
  storeColumns: YColumnsType<PickingIssuesData>[],
): ColumnsType<PickingIssuesData> => {
  const columns: ColumnsType<PickingIssuesData> = [...storeColumns];
  columns.push(
    {
      title: 'Quantity',
      key: 'quantity',
      align: 'right',
      width: computeRemSize(120),
      render: (record: PickingIssuesData) => {
        const diff = record.quantity - record.executedQuantity;
        const value = `${record.executedQuantity} / ${record.quantity}   ( ${formatNumber(-diff)} )`;

        const observations = data.map(record => record.quantity - record.executedQuantity);
        const outlier = isOutlier(observations, diff);
        return outlier ? (
          <OutlierValue value={value} tooltip={outlierValueTooltip} center />
        ) : (
          value
        );
      },
      sorter: (a: PickingIssuesData, b: PickingIssuesData) => {
        //when sorted in ascending order, the smallest difference should be first
        //so diff is a positive number
        const diffA = a.quantity - a.executedQuantity;
        const diffB = b.quantity - b.executedQuantity;

        return diffA - diffB;
      },
    },
    {
      title: 'Value',
      key: 'value',
      align: 'right',
      width: computeRemSize(180),
      render: (record: PickingIssuesData) => {
        const diff = record.value - record.executedValue;
        const value = `${formatNumber(record.executedValue)} / ${formatNumber(record.value)}   ( ${formatNumber(-diff)} )`;
        const observations = data.map(record => record.value - record.executedValue);
        const outlier = isOutlier(observations, diff);
        return outlier ? (
          <OutlierValue value={value} tooltip={outlierValueTooltip} center />
        ) : (
          value
        );
      },
      sorter: (a: PickingIssuesData, b: PickingIssuesData) => {
        //when sorted in ascending order, the smallest difference should be first
        //so diff is a positive number
        const diffA = a.value - a.executedValue;
        const diffB = b.value - b.executedValue;

        return diffA - diffB;
      },
    },
    {
      title: 'SKU Count',
      dataIndex: 'skuCount',
      key: 'skuCount',
      align: 'right',
      width: computeRemSize(120),
    },
    {
      title: 'Total Issues',
      dataIndex: 'count',
      key: 'totalIssuesCount',
      align: 'center',
      width: computeRemSize(100),
      render: (value: number) => {
        const observations = data.map(record => record.count);
        const outlier = isOutlier(observations, value);
        return outlier ? (
          <OutlierValue value={value} tooltip={outlierValueTooltip} center />
        ) : (
          value
        );
      },
      sorter: (a: PickingIssuesData, b: PickingIssuesData) => a.count - b.count,
    },
  );

  /****** Dynamically add columns for used issues only ******************/
  //determine what issues are used in dataset
  const usedIssueTypes: Set<PapRedistributionIssue> = new Set();
  data.forEach(record => {
    record.issues.forEach(issue => usedIssueTypes.add(issue.papRedistributionIssueId));
  });

  //if the column definitions were not created yet, created them
  if (issueColumnsMap.size === 0) {
    generateIssueColumnsMap();
  }

  //add columns for used issues only
  Array.from(usedIssueTypes)
    .sort(issueTypeSorter) //sort by title alphabetically, Other is always last
    .forEach(issueType => {
      const column = issueColumnsMap.get(issueType);
      if (column) {
        columns.push(column);
      }
    });

  return columns;
};

/**
 * Generate the columns for the picking issues of a single source store table
 * @param targetStoreColumns columns for the target store based on unified rules
 * @param productColumns columns for product
 */
export const getSourceStorePickingIssuesColumns = (
  targetStoreColumns: YColumnsType<PickingIssueResponse>[],
  productColumns: YColumnsType<PickingIssueResponse>[],
): ColumnsType<PickingIssueResponse> => {
  const columns: ColumnsType<PickingIssueResponse> = [
    ...productColumns,
    {
      title: 'Brand',
      dataIndex: ['product', 'brand', 'name'],
      sorter: (a: PickingIssueResponse, b: PickingIssueResponse) => {
        return a.product.brand?.name.localeCompare(b.product.brand?.name ?? '') ?? 0;
      },
    },
  ];

  columns.push(...targetStoreColumns);

  columns.push(
    {
      title: 'Issue',
      key: 'issue',
      width: computeRemSize(140),
      sorter: (a: PickingIssueResponse, b: PickingIssueResponse) => {
        const issueA = translateRedistributionIssue(a.papRedistributionIssueId);
        const issueB = translateRedistributionIssue(b.papRedistributionIssueId);
        return issueA.localeCompare(issueB);
      },
      render: (record: PickingIssueResponse) =>
        translateRedistributionIssue(record.papRedistributionIssueId),
    },
    {
      title: 'Quantity',
      key: 'quantity',
      align: 'right',
      width: computeRemSize(120),
      render: (record: PickingIssueResponse) => {
        const diff = record.quantityExecuted - record.quantityRequested;
        return `${record.quantityExecuted} / ${record.quantityRequested}   ( ${formatNumber(diff)} )`;
      },
    },
  );

  return columns;
};
