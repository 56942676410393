import { useDispatch, useSelector } from 'react-redux';
import { notification } from 'antd';
import { removeToast } from '../../../../../../libs/utils/src/lib/toastSlice';
import React, { useCallback, useEffect } from 'react';
import { ArgsProps, NotificationPlacement } from 'antd/lib/notification/interface';
import { Toast } from '@ydistri/utils';

const NOTIFICATION_PLACEMENT: NotificationPlacement = 'bottomRight';

/**
 * Show all notifications that are currently in the toast store
 * Errors are displayed a little longer than success messages.
 */
const ToastContainer: React.FC = () => {
  const toasts = useSelector(state => state.toastReducer.toasts);
  const dispatch = useDispatch();
  const [api, contextHolder] = notification.useNotification();

  const showToast = useCallback(
    (toast: Toast) => {
      //remove the toast first, so we do not show it again
      //when a new toast is added when the user is still looking at the old one
      dispatch(removeToast(toast));

      const notification: ArgsProps = {
        message: toast.message,
        description: toast.description,
        placement: NOTIFICATION_PLACEMENT,
        duration: 3,
      };

      if (toast.isError) {
        notification.duration = 4;
        api.error(notification);
      } else {
        api.success(notification);
      }
    },
    [api, dispatch],
  );

  useEffect(() => {
    toasts.forEach(toast => showToast(toast));
  }, [toasts, showToast]);

  return contextHolder;
};

export default ToastContainer;
